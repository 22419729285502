<template>
  <div>
    <div>
      <slot />
    </div>
    <ClientOnly>
      <SnackBar />
    </ClientOnly>
  </div>
</template>

<script setup>
useHead({
  script: [
    {
      src: 'https://x.klarnacdn.net/kp/lib/v1/api.js',
      body: true,
      defer: true,
    },
  ],
})
</script>
